@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Manrope', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas,'Manrope', 'Courier New',
    monospace;
}

@media screen and (min-width: 768px) {
  .project-image {
    height: 450px
  }
}

.diagonal {
  transform: skewY(-11deg);
}

.content {
  transform: skewY(11deg);
}

.text-heading { 
  font-size: 2.075rem;
  line-height: 2.375rem;
}